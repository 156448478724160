.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.custom-badge {
  background: red !important;
}
.App-link {
  color: #61dafb;
}
.social-media-img {
  height: 30px;
  width: 30px;
}
.first-letter-cap::first-letter {
  text-transform: capitalize;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.css-f63afk-MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  border: 1px solid #02b2af !important;
}
.accordion-Header {
  height: auto !important;
  margin: 0rem !important;
  min-height: 20px !important;
}

a {
  text-decoration: none !important;
}

.decoration-underline {
  text-decoration: underline !important;
}

.video-container {
  width: 700px;
  height: 500px;
  max-width: 90vw;
  max-height: 50vh;
  margin: 0 auto;
  border: 1px solid #0acf83;
  position: relative;
  box-shadow: 1px 1px 11px #9e9e9e;
  background-color: #fff;
}

.video-container .action-btns {
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -50px;
  z-index: 3;
  display: flex;
  flex-direction: row;
}

/* Mobiel Styles */
@media only screen and (max-width: 768px) {
  .video-container {
    height: 50vh;
  }
}

.video-row {
  width: 700px;
  max-width: 90vw;
}
