:root {
  --filter-heading-color: #1c1d1f;
  --borderColor: #d7dcdf;
}
.font-12 {
  font-size: 10px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-18 {
  font-size: 19px !important;
}
.font-17 {
  font-size: 19px !important;
}
.font-700 {
  font-weight: 700 !important;
}
.font-600 {
  font-weight: 700 !important;
}
.font-500 {
  font-weight: 500 !important;
}
.font-14 .css-ahj2mt-MuiTypography-root {
  font-size: 14px !important;
}
.font-12 .css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
}
.MuiLinearProgress-root
  .MuiLinearProgress-colorPrimary
  .MuiLinearProgress-determinate
  .css-1gdnuqf-MuiLinearProgress-root {
  border: 1px solid #02b2af !important;
}

.form {
  width: 100%;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 40px;

  &:after {
    content: attr(data-text);
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 8.5px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: 40px - 20px;
    line-height: 40px - 20px;
    color: #999;
    border-radius: 5px 3px 3px 5px;
    font-weight: 300;
    border: 1px solid !important;
    height: 40px;
  }

  &:before {
    content: attr(data-button-text);
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: 40px;
    background: #f7f9fa;
    color: #000;
    font-weight: 600;
    z-index: 25;
    font-size: 16px;
    line-height: 36px;
    padding: 2px 15px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0 3px 3px 0;
    border: 1px solid !important;
  }

  &:hover {
    &:before {
      background: darken(#f7f9fa, 10%);
    }
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 40px - 20px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
}
.custom-list-text {
  .css-10hburv-MuiTypography-root {
    display: contents;
  }
  .MuiListItemText-primary {
    display: contents;
  }
}

.custom-tab-parent-container {
  height: 100% !important;
}
.custom-dashboard-tab {
  .MuiTab-textColorPrimary {
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 500;
  }
  .Mui-selected {
    background-color: #fff !important;
    color: #1c75bb !important;
    font-size: 20px !important;
    font-weight: 700;
  }
  .MuiTabs-flexContainer {
    height: 100%;
  }
  .MuiTabs-indicator {
    background: none !important;
  }
}
.custom-dashboard-tab-orange {
  .MuiTab-textColorPrimary {
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 500;
  }
  .Mui-selected {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 20px !important;
    font-weight: 700;
  }
  .MuiTabs-flexContainer {
    height: 100%;
  }
  .MuiTabs-indicator {
    background: none !important;
  }
}
.padding-0 {
  padding: 0rem !important;
}
.custom-mui-table-row {
  border: none !important;
  td {
    padding: 0rem !important;
    div {
      background: #bfdae1;
      padding: 0.8rem;
      margin-top: 0.8rem;
      border-right: 1px solid #fff;
    }
  }
  td:first-child {
    div {
      margin-left: 0.8rem;
    }
  }
  td:last-child {
    div {
      margin-right: 0.8rem;
    }
  }
}
.custom-mui-table-body .custom-mui-table-row:last-child {
  td {
    div {
      margin-bottom: 0.8rem;
    }
  }
}

.rank-custom-badge {
  .MuiBadge-badge {
    background: #ffbb00;
  }
}
.course-list-table {
  tr:nth-child(even) {
    background-color: #eef7fa;
  }

  tr:nth-child(odd) {
    background-color: #def0f5;
  }
}
.analytics-custom-mui-table-body {
  tr:nth-child(even) {
    background-color: #eef7fa;
  }

  tr:nth-child(odd) {
    background-color: #def0f5;
  }
}
.table-div-one {
  padding: 0.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #def0f5;
  color: #10496a;
  font-weight: 700;
  font-size: 18px;
}
.table-div-two {
  padding: 0.3rem;
  background: #eef7fa;
  color: #10496a;
}
.acedmic-card-content {
  padding-bottom: 0rem !important;
}

.accordion-Header {
  .MuiAccordionSummary-content {
    width: 100%;
    min-width: 100% !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.wrap-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.webkit-box-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.menu-tool-header {
  min-height: auto !important;
}

#panel1a-header-subname {
  .MuiAccordionSummary-content {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.avatar-img-object-fit {
  img {
    object-fit: contain;
  }
}

.custom-rating {
  .MuiRating-decimal {
    .MuiRating-icon {
      color: #ffbb00 !important;
    }
  }
}

.comming-soon-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px); /* Blurs the background */
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3); /* Light transparent overlay */
  font-size: 24px;
  font-weight: bold;
  color: #333;
  z-index: 1;
}

.comming-soon-parent {
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  position: relative;
  background-image: url('https://via.placeholder.com/300x200'); /* Replace with your image */
  background-size: cover;
  background-position: center;
}
