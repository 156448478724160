/* ----------------------------------------
// Footer
// ---------------------------------------- */
/*
// .footer-contacts
*/
.footer-contacts__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1), (max-resolution: 1dppx) {
  .footer-contacts__title {
    font-weight: 400;
  }
}

.footer-contacts__text {
  line-height: 22px;
  list-style: none;
  margin: 0;
  font-size: 15px;
}

.footer-contacts__text a {
  color: inherit;
}

.footer-contacts__contacts {
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-contacts__contacts a {
  color: inherit;
}

.footer-contacts__contacts dt {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.footer-contacts__contacts dd {
  color: #fff;
  margin: 0;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1), (max-resolution: 1dppx) {
  .footer-contacts__contacts dd {
    font-weight: 400;
  }
}

.footer-contacts__icon {
  display: inline-block;
  text-align: center;
  width: 22px;
  margin-right: 2px;
}

@media (min-width: 1400px) {
  [dir='ltr'] .footer-contacts {
    padding-right: 36px;
  }
  [dir='rtl'] .footer-contacts {
    padding-left: 36px;
  }
}

@media (min-width: 1200px) {
  .footer-contacts__contacts {
    margin: 24px -15px 0;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 2 - 30px - 1px);
    margin: 0 15px;
  }
  .footer-contacts__contacts dl:nth-child(n + 3) {
    margin-top: 18px;
  }
}

@media (max-width: 1199.98px) {
  .footer-contacts__contacts {
    margin: -6px -6px;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 4 - 12px);
    margin: 6px 6px;
  }
  .footer-contacts {
    text-align: center;
    margin-bottom: 42px;
  }
  .footer-contacts__title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .footer-contacts__contacts {
    padding-top: 28px;
  }
  .footer-contacts__contacts dl {
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 12px;
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 991.98px) {
  .footer-contacts__contacts {
    margin: -6px -6px;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 2 - 12px);
    margin: 6px 6px;
  }
  .footer-contacts__contacts dl {
    padding: 20px 16px;
  }
}

@media (max-width: 767.98px) {
  .footer-contacts__contacts dl {
    padding: 16px;
  }
}

@media (max-width: 479px) {
  .footer-contacts__contacts {
    margin: -4px -4px;
  }
  .footer-contacts__contacts dl {
    width: calc(100% / 1 - 8px);
    margin: 4px 4px;
  }
}
/*
// .footer-links
*/
.footer-links__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 22px;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1), (max-resolution: 1dppx) {
  .footer-links__title {
    font-weight: 400;
  }
}

.footer-links__list {
  font-size: 15px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links__list a {
  color: inherit;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}

.footer-links__list a:hover {
  color: #fff;
  font-weight: 700;
}

.footer-links__item {
  padding: 5px 0;
}

@media (max-width: 767.98px) {
  .footer-links {
    text-align: center;
  }
  .footer-links__title {
    margin-bottom: 12px;
  }
}
/*
// .footer-newsletter
*/
.footer-newsletter__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1), (max-resolution: 1dppx) {
  .footer-newsletter__title {
    font-weight: 400;
  }
}

.footer-newsletter__text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 14px;
}

.footer-newsletter__text--social {
  margin-top: 20px;
  margin-bottom: 0;
}

.footer-newsletter__social-links {
  margin-top: 8px;
}

.footer-newsletter__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 380px;
}

.footer-newsletter__form-input {
  -ms-flex-negative: 1;
  flex-shrink: 1;
  border-radius: 2px;
  font-size: 15px;
  height: 38px;
  padding: 0 12px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  min-width: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-family: inherit;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-clip: padding-box;
  -webkit-transition: border 0.2s, background 0.2s;
  transition: border 0.2s, background 0.2s;
  color: #fff;
  background-color: #005e89;
  border-color: #0e4d6a;
}

.footer-newsletter__form-input::-webkit-input-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::-moz-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input:-ms-input-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::-ms-input-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::-webkit-input-placeholder {
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.footer-newsletter__form-input::-moz-placeholder {
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}

.footer-newsletter__form-input:-ms-input-placeholder {
  -ms-transition: color 0.2s;
  transition: color 0.2s;
}

.footer-newsletter__form-input::-ms-input-placeholder {
  -ms-transition: color 0.2s;
  transition: color 0.2s;
}

.footer-newsletter__form-input::placeholder {
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.footer-newsletter__form-input:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.16);
}

.footer-newsletter__form-input:focus {
  outline: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.16);
}

.footer-newsletter__form-input:focus::-webkit-input-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus::-moz-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus:-ms-input-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus::-ms-input-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus::placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-button {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 2px;
  border: none;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  -webkit-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
  background-color: #e52727;
  color: #fff;
  background-color: #e52727;
  color: #fff;
}

.footer-newsletter__form-button:hover {
  background-color: #545454;
  color: #fff;
}

.footer-newsletter__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}

.footer-newsletter__form-button {
  margin-left: 8px;
}

.footer-newsletter__form-button {
  margin-right: 8px;
}

.footer-newsletter__form-button:hover {
  background-color: #545454;
  color: #fff;
}

.footer-newsletter__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}

.footer-newsletter__form-button:active {
  -webkit-transition-duration: 0.1s, 0.1s;
  transition-duration: 0.1s, 0.1s;
}

.footer-newsletter__form-button:focus {
  outline: none;
}

@media (max-width: 767.98px) {
  .footer-newsletter {
    text-align: center;
    margin-top: 28px;
  }
  .footer-newsletter__title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .footer-newsletter__form {
    margin: 0 auto;
  }
  .footer-newsletter__text--social {
    margin-top: 24px;
  }
  .footer-newsletter__social-links {
    margin-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
/*
// .site-footer
*/
.site-footer {
  position: relative;
  background-color: #0e4d6a;
  color: #fff;
}

.site-footer__decor {
  position: absolute;
  bottom: calc(100% - 1px);
}

.site-footer__decor .decor__start,
.site-footer__decor .decor__end,
.site-footer__decor .decor__center {
  background: #333;
}

.site-footer__decor .decor__start,
.site-footer__decor .decor__end {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.site-footer__widgets {
  padding: 56px 0 50px;
}

.site-footer__bottom {
  background-color: #083c55;
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
}

.site-footer__bottom a {
  color: #fff;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1), (max-resolution: 1dppx) {
  .site-footer__bottom a {
    font-weight: 400;
  }
}

.site-footer__bottom-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .site-footer__decor {
    display: none;
  }
  .site-footer__widgets {
    padding: 40px 0 36px;
  }
  .site-footer__bottom-row {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 0 24px;
  }
  .site-footer__payments {
    margin-top: 12px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}
